import firebase from "firebase/app";

// Add the Firebase services that you want to use
// We only want to use Firebase Auth here
import "firebase/auth";

// Your app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyB29-sf_Cq8KjnPId41_UbjjMeA7w9Zce4",
    authDomain: "scrutiny-f48fa.firebaseapp.com",
    databaseURL: "https://scrutiny-f48fa.firebaseio.com",
    projectId: "scrutiny-f48fa",
    storageBucket: "scrutiny-f48fa.appspot.com",
    messagingSenderId: "599233696049"
  }

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Finally, export it to use it throughout your app
export default firebase;