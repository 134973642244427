import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import clsx from 'clsx';

import theme from '../../../theme'
import firebase from '../../../utils/firebase';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon
} from 'react-feather';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NavItem from './NavItem';
import ClipLoader from "react-spinners/ClipLoader";
import {signout} from '../../../actions/AuthActions'

const items = [
  // {
  //   href: '/app/dashboard',
  //   icon: BarChartIcon,
  //   title: 'Dashboard'
  // },
  {
    href: '/app/projects',
    icon: UsersIcon,
    title: 'Projects'
  },
  // {
  //   href: '/app/products',
  //   icon: ShoppingBagIcon,
  //   title: 'Products'
  // },
  // {
  //   href: '/app/account',
  //   icon: UserIcon,
  //   title: 'Account'
  // },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings'
  // },
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login',
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register'
  // },

];
const navStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 4px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }, 
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

function NavBar ({ onMobileClose, openMobile, auth }) {
  const classes = useStyles();
  const navClasses = navStyles();
  const location = useLocation();
  let navigate = useNavigate();
  function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSignOutConfirmed = () => {
    try {
      firebase
      .auth()
      .signOut()
      .then(() => {
        navigate('/auth')
      })
      .catch(() => {
        console.log('Something went wrong')
      });
    } catch (err) {
      console.log(err)
    }    
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
        {
          !auth.profile.isLoaded ? 
          <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          p={2}
        >                    
          <ClipLoader
          size={105}
          color={theme.palette.primary.main}
          />  
          </Box>
          :
          <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          p={2}
        >
          <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={auth.photoURL}
          to="/app/account"
          />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
          >
          {auth.profile.firstName} {auth.profile.lastName}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
          >
          {auth.profile.profession}
        </Typography>
          </Box>
        }
        
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}

<ListItem
      className={clsx(navClasses.item, navClasses)}
      disableGutters
    >
      <Button
        onClick={handleClickOpen}
        activeClassName={navClasses.active}
        className={navClasses.button}
        >
          <ExitToAppIcon
            className={classes.icon}
            size="20"
          />
        <span className={classes.title}>
        Sign Out
        </span>
      </Button>
    </ListItem>
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box
        p={2}
        m={2}
        bgcolor="background.dark"
      >
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Got feedback?
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Feel free to get in touch with any bugs, requests or ideas
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            component="a"
            onClick={() => window.open('mailto:support@scrutiny.app?subject=Feedback')}
            variant="contained"
          >
            Talk to us!
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to sign out?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            No
          </Button>
          <Button onClick={() => {
            onSignOutConfirmed();
            handleClose()
            }} color="error" >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

function mapStateToProps(state) {
  return {
      auth: state.firebaseReducer
  }
}


export default connect(mapStateToProps, {signout})(NavBar);
