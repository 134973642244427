export const EMAIL_CHANGED = 'email_changed';
export const PASSWORD_CHANGED = 'password_changed';
export const LOGIN_USER = 'login_user';
export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_FAIL = 'login_user_fail';
export const LOGOUT_USER = 'logout_user';
export const SIGNED_OUT = 'signed_out';
export const SIGNED_IN = 'signed_in';

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNOUT_ERROR = "SIGNOUT_ERROR";

export const PROJECT_CREATE = 'project_create';
export const PROJECT_CREATED = 'project_created';
export const PROJECT_FETCH_SUCCESS = 'project_fetch_success';
export const PROJECT_DELETED = 'project_deleted';
export const SEARCH_FETCH_SUCCESS = 'search_fetch_success';
export const SEARCH_FETCH_STARTED = 'search_fetch_success';
export const DOCUMENT_FETCH_SUCCESS = 'document_fetch_success';
export const DOCUMENT_FETCH_STARTED = 'document_fetch_started';
export const PROJECT_SEARCH_TERMS = 'project_search_terms';
export const ADD_SEARCH_TO_SHARE = 'add_search_to_share';
export const REMOVE_SEARCH_TO_SHARE = 'remove_search_to_search';
export const CLEAR_DOCUMENTS = 'clear_documents';
export const SEARCH_STRING_ADD = 'search_string_add';
export const ITEM_PROCESSED = 'item_processed';
export const SORT_DATE = 'sort_date';
export const ADD_TEMP_ITEM = 'add_temp_item';
export const CLEAR_PROJECTS = 'clear_projects';
export const DOCUMENT_SEARCH = 'document_search';
export const PROJECT_SEARCH = 'project_search';
export const RIS_GENERATED = 'ris_generated';
export const ONLY_READ = 'only_read';
export const ONLY_UNREAD = 'only_unread';
export const VIEW_ALL = 'view_all';

export const GEN_RIS_STARTED = 'gen_ris_started';