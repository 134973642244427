import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import ProjectReducer from './ProjectReducer';
import ProjectList from './ProjectList';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore' // <- needed if using firestore


export default combineReducers({
    auth: AuthReducer,
    project: ProjectReducer,
    projectList: ProjectList,
    firebaseReducer,
    firestoreReducer // <- needed if using firestore
});