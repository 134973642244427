import firebase from 'firebase';
import { saveAs } from 'file-saver';
import {
    PROJECT_CREATE,
    PROJECT_CREATED,
    PROJECT_FETCH_SUCCESS,
    PROJECT_DELETED,
    SEARCH_FETCH_SUCCESS,
    SEARCH_FETCH_STARTED,
    DOCUMENT_FETCH_SUCCESS,
    DOCUMENT_FETCH_STARTED,
    PROJECT_SEARCH_TERMS,
    ADD_SEARCH_TO_SHARE,
    REMOVE_SEARCH_TO_SHARE,
    CLEAR_DOCUMENTS,
    PROJECT_SEARCH,
    ITEM_PROCESSED,
    SORT_DATE,
    DOCUMENT_SEARCH,
    CLEAR_PROJECTS,
    RIS_GENERATED,
    ONLY_READ,
    ONLY_UNREAD,
    VIEW_ALL,
    GEN_RIS_STARTED
} from './types';


export const projectCreate = ({ prop, value }) => ({
    type: PROJECT_CREATE,
    payload: { prop, value },
});

export const viewAllPressed = () => ({
    type: VIEW_ALL,

});

export const onlyReadPressed = () => ({
    type: ONLY_READ,

});

export const onlyUnreadPressed = () => ({
    type: ONLY_UNREAD,

});

export const projectSearchTerms = text => ({
    type: PROJECT_SEARCH_TERMS,
    text,
});



export const projectInit = ({ projectName, initializedDate, type }) => {
    const { currentUser } = firebase.auth();
    const db = firebase.firestore();
    return (dispatch) => {
        db.collection('users').doc(currentUser.uid).collection('projects').add({
            projectName,
            initializedDate,
            type,
        })
            .then(() => {
                dispatch({ type: PROJECT_CREATED });
            });
    };
};

export const sortChange = (value) => {
    if (value === 'key0') {
        return { type: SORT_DATE, sortBy: 'initializedDate', sortType: 'desc' };
    } else if (value === 'key1') {
        return { type: SORT_DATE, sortBy: 'initializedDate', sortType: 'asc' };
    } else if (value === 'key2') {
        return { type: SORT_DATE, sortBy: 'projectName', sortType: 'asc' };
    }
};

export const deletePaperProject = (projectUID, searchUID, itemUID) => {
    const { currentUser } = firebase.auth();
    const db = firebase.firestore();
    return (dispatch) => {
        db.collection('users').doc(currentUser.uid).collection('projects').doc(projectUID).collection('searches').doc(searchUID).collection('LikedDocs').doc(itemUID)
            .delete();
    }
}

export const deletePaperSearch = (searchUID, itemUID) => {
    const { currentUser } = firebase.auth();
    const db = firebase.firestore();
    return (dispatch) => {
        db.collection('users').doc(currentUser.uid).collection('projects').doc(searchUID).collection('LikedDocs').doc(itemUID)
            .delete();
    }
}

export const deleteProject = (uid) => {
    const { currentUser } = firebase.auth();
    const db = firebase.firestore();
    return (dispatch) => {
        db.collection('users').doc(currentUser.uid).collection('projects').doc(uid)
            .delete();
    };
};

export const generateRis = (docs, title) => {
    var shareString = '';
    console.log(docs, title)
    try {
        Object.keys(docs).forEach(function (key) {
            var authors = docs[key].item.authors;

            var printAuthors = ''
            authors.forEach(function (item) {
                printAuthors += `AU  - ${String(item.last_name)}, ${String(item.first_name)}\n`
            })
            shareString += `TY  - generic\nIS  - ${String(docs[key].item.issue)}\n${printAuthors}VL  - ${String(docs[key].item.volume)}\nPY  - ${String(docs[key].item.year)}\nAB  - ${String(docs[key].item.abstract)}\nDO  - ${String(docs[key].item.identifiers.doi)}\nTI  - ${String(docs[key].item.title)}\nT2  - ${String(docs[key].item.source)}\nER  -\n`;
        })
        var blob = new Blob([shareString], {
            type: "text/plain;charset=utf-8"
        });

        saveAs(blob, `references.ris`);
        return { type: RIS_GENERATED }
    } catch {
        console.log('error')
        return { type: RIS_GENERATED }

    }
}

export const startDownload = () => {
    return {type: GEN_RIS_STARTED}
}

export const generateSingleRis = (doc, title) => {
    var shareString = '';
    try {
            var authors = doc.authors;
            var printAuthors = ''
            authors.forEach(function (item) {
                printAuthors += `AU  - ${String(item.last_name)}, ${String(item.first_name)}\n`
            })

            shareString += `TY  - generic\nIS  - ${String(doc.issue)}\n${printAuthors}VL  - ${String(doc.volume)}\nPY  - ${String(doc.year)}\nAB  - ${String(doc.abstract)}\nDO  - ${String(doc.identifiers.doi)}\nTI  - ${String(doc.title)}\nT2  - ${String(doc.source)}\nER  -\n`;
        
        var blob = new Blob([shareString], {
            type: "text/plain;charset=utf-8"
        });
        saveAs(blob, `${title}.ris`);
        return ({ type: RIS_GENERATED })
    } catch {
        console.log('error')
        return ({ type: RIS_GENERATED })
    }
}
export const deleteSearch = (projectUID, searchUID) => {
    const { currentUser } = firebase.auth();
    const db = firebase.firestore();
    return (dispatch) => {
        db.collection('users').doc(currentUser.uid).collection('projects').doc(projectUID).collection('searches').doc(searchUID)
            .delete();
    };
};

export const documentSearch = text => ({
    type: DOCUMENT_SEARCH,
    text,
});

export const projectSearch = text => ({
    type: PROJECT_SEARCH,
    text,
});


export const projectFetch = () => {
    const { currentUser } = firebase.auth();
    const db = firebase.firestore();
    const projects = {};
    return (dispatch) => {
        db.collection('users').doc(currentUser.uid).collection('projects')
            .onSnapshot((querySnapshot) => {
                const projects = {};
                querySnapshot.forEach((doc) => {
                    const key = doc.id;
                    const project = doc.data();
                    const obj = {
                        key,
                        project,
                    };
                    projects[key] = project;
                });
                dispatch({ type: PROJECT_FETCH_SUCCESS, payload: projects });
            });
    };
};

export const setRead = (projectUID, searchUID, itemUID, readStatus) => {
    const { currentUser } = firebase.auth();
    const update = !readStatus
    const db = firebase.firestore();
    console.log('p', projectUID, 's', searchUID, 'i', itemUID)
    try {
        if (projectUID) {
            return (dispatch) => {
                db.collection('users').doc(currentUser.uid).collection('projects').doc(projectUID)
                    .collection('searches').doc(searchUID).collection('LikedDocs').doc(itemUID).set({ item: { readStatus: update } }, { merge: true })
            }
        } else {
            return (dispatch) => {
                db.collection('users').doc(currentUser.uid).collection('projects').doc(searchUID)
                    .collection('LikedDocs').doc(itemUID).set(({ item: { readStatus: update } }), { merge: true })
            }
        }
    } catch (e) {
        console.log(e);
    }
};

export const getSearches = (projectUID, name, date) => {
    const { currentUser } = firebase.auth();
    const db = firebase.firestore();
    return (dispatch) => {
        dispatch({ type: SEARCH_FETCH_STARTED });
        db.collection('users').doc(currentUser.uid).collection('projects').doc(projectUID)
            .collection('searches')
            .onSnapshot((querySnapshot) => {
                const searches = {};
                querySnapshot.forEach((doc) => {
                    const key = doc.id;
                    const project = doc.data();
                    searches[key] = project;
                });
                dispatch({ type: SEARCH_FETCH_SUCCESS, searches, name, date, projectUID });
            });
    };
};

// const getDocs = () => async(dispatch) => {
//   try {
//     const { currentUser } = firebase.auth();
//     const db = firebase.firestore();
//     const likedDocs = await db.collection('users').doc(currentUser.uid).collection('projects').doc(projectUID)
//     .collection('searches')
//     .doc(searchUID)
//     .collection('LikedDocs')
//     .onSnapshot((querySnapshot) => {
//       const documents = {};
//       querySnapshot.forEach((doc) => {
//         const key = doc.id;
//         const project = doc.data();
//         documents[key] = project;
//       });
//       return likedDocs;
//   })
// } catch (e) {
//   console.log(e);
// }
// };


export const getLikedDocs = (searchUID, name, date) => {
    const { currentUser } = firebase.auth();
    const db = firebase.firestore();
    return (dispatch) => {
        dispatch({ type: DOCUMENT_FETCH_STARTED });
        db.collection('users').doc(currentUser.uid).collection('projects').doc(searchUID)
            .collection('LikedDocs')
            .onSnapshot((querySnapshot) => {
                const documents = {};
                querySnapshot.forEach((doc) => {
                    const key = doc.id;
                    const project = doc.data();
                    documents[key] = project;
                });
                dispatch({ type: DOCUMENT_FETCH_SUCCESS, documents, name, date, searchUID });
            });
    };
};

export const addSearchToShare = searchUID => ({
    type: ADD_SEARCH_TO_SHARE,
    searchUID,
});

export const deleteSearchToShare = searchUID => ({
    type: REMOVE_SEARCH_TO_SHARE,
    searchUID,
});


export const clearDocuments = () => ({
    type: CLEAR_DOCUMENTS,
});

export const clearProjects = () => ({
    type: CLEAR_PROJECTS,
})


export const itemProcessed = () => ({
    type: ITEM_PROCESSED,
});

export const projectGetLikedDocs = (projectUID, searchUID, name) => {
    const { currentUser } = firebase.auth();
    const db = firebase.firestore();
    return (dispatch) => {
        dispatch({ type: DOCUMENT_FETCH_STARTED });
        db.collection('users').doc(currentUser.uid).collection('projects').doc(projectUID)
            .collection('searches')
            .doc(searchUID)
            .collection('LikedDocs')
            .onSnapshot((querySnapshot) => {
                const documents = {};
                querySnapshot.forEach((doc) => {
                    const key = doc.id;
                    const project = doc.data();
                    documents[key] = project;
                });
                dispatch({ type: DOCUMENT_FETCH_SUCCESS, documents, name, searchUID });
            });
    };
};

// const fetchData = (projectUID, searchUID) => {
//     const { currentUser } = firebase.auth();
//     const db = firebase.firestore();
//     db.collection('users').doc(currentUser.uid).collection('projects').doc(projectUID)
//         .collection('searches')
//         .doc(searchUID)
//         .collection('LikedDocs')
//         .onSnapshot((querySnapshot) => {
//             const documents = {};
//             querySnapshot.forEach((doc) => {
//                 const key = doc.id;
//                 const project = doc.data();
//                 documents[key] = project;
//             });
//             console.log('thelikeddocs are', documents);
//             let shareString = '';
//             Object.keys(documents).forEach((key) => {
//                 shareString += `${String(documents[key].item.title)}\nDOI: https://dx.doi.org/${String(documents[key].item.identifiers.doi)}\nPubmed: https://www.ncbi.nlm.nih.gov/pubmed/?term=${String(documents[key].item.identifiers.pmid)}\n Scopus: https://www.scopus.com/record/display.uri?eid=${String(documents[key].item.identifiers.scopus)}&origin=resultslist\n \n`;
//             });

//             this.setState({ bigString: this.state.bigString += shareString });

//             console.log('the share string is', shareString);
//             console.log('the big string is', this.state.bigString);
//             return shareString;
//         });
// };

// export const firestorereq = (projectUID, searchUIDs) => async () => {
//     let finalString = '';
//     console.log(searchUIDs);
//     Object.values(searchUIDs).forEach(async (value) => {
//         const oneString = await fetchData(projectUID, value);
//         console.log('the passed string is', oneString);
//         finalString += oneString;
//         console.log('currently the final string is', finalString);
//     });
// };