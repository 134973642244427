import React from 'react';
import { Link as RouterLink, useNavigate,} from 'react-router-dom';
import { connect } from 'react-redux'
import * as Yup from 'yup';
import { Formik } from 'formik';
import { signin } from '../../actions/AuthActions'
import { palette } from '@material-ui/system';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import firebase from "firebase";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',

  },
  snackbarStyleViaContentProps: {
    backgroundColor: "transparent"
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: "lightgreen",
    color: "black"
  }
}));


function ResetView () {
  let navigate = useNavigate();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [open, setOpen] = React.useState(false);

  const openSnack = () => {
    setOpen(true);
  };

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }
  
  function handleSubmit (values) {
    firebase
    .auth()
    .sendPasswordResetEmail(values.email)
    .then(res => {
      formikRef.current.setSubmitting(false);
      openSnack();
      console.log(res)
    })
    .catch(error => {
      formikRef.current.setSubmitting(false);
      console.log(error);
      openSnack();
    });
  }

  const classes = useStyles();
  const formikRef = React.createRef();

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        bgcolor='primary.main'
      >
        <Container 
        maxWidth="sm"
        bgcolor='primary.main'
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            bgcolor='white'
            padding={5}
            borderRadius={20}
          >
          <Formik
            innerRef={formikRef}
            initialValues={{
              email: 'example@gmail.com',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            })}
            onSubmit={(values) => {   
            handleSubmit(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit} bgcolor='white'>
                <Box
                  flexDirection="row"
                >
                  <Link variant="h6"
                  to="/auth/login"
                  component={RouterLink}
                  alignItems='center'
                  >
                    <CloseIcon/>
                  </Link>   
                  
                  <Typography
                    color="primary"
                    variant="h2"
                  >
                    reset password
                  </Typography>
                </Box>
                <Box
                  mt={1}
                  mb={1}
                  ml={10}
                  mr={10}
                  bgcolor='white'
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"

                  >
                  Enter the email address you used to sign up and we'll send a password reset link
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box my={2} >
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Reset password
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Still having trouble?
                  {' '}
                  <Link
                    onClick={() => {
                    openInNewTab('https://scrutiny.app/page-company-contact.html')
                    }}
                    variant="h6"
                  >
                    Click here to talk to support
                   </Link>
                </Typography>
              </form>
            )}
          </Formik>
          </Box>
        </Container>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={closeSnack}>
        <Alert onClose={closeSnack} severity='success'>
          Check your e-mail! If an account exists please follow the further instructions to recover your account
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default connect(null, {signin})(ResetView);
