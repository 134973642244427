import React, { useState } from "react";
import Toolbar from "./Toolbar";
import {
  Box,
  Container,
  makeStyles,
  Avatar,
  Card,
  Checkbox,
  Typography,
  InputAdornment,
  SvgIcon,
  Button,
  CardContent,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { useNavigate, useParams } from "react-router-dom";
import { Search as SearchIcon } from "react-feather";
import { startDownload, generateRis } from "../../actions/ProjectActions";

import ClipLoader from "react-spinners/ClipLoader";
import theme from "../../theme";
import _ from "lodash";
import Page from "src/components/Page";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import FolderIcon from "@material-ui/icons/Folder";
import PerfectScrollbar from "react-perfect-scrollbar";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    card: {},
  },
}));

function SearchPage({ database, auth }) {
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const classes = useStyles();
  let path = "";
  let data = "";
  let searchtitle = "";

  let { sid, pid } = useParams();
  if (typeof pid === "undefined") {
    path = `users/${auth.uid}/projects/${sid}/LikedDocs`;
  } else {
    path = `users/${auth.uid}/projects/${pid}/searches/${sid}/LikedDocs`;
  }

  useFirestoreConnect({
    collection: `users/${auth.uid}/projects`,
    storeAs: "projects",
  });
  useFirestoreConnect([
    {
      collection: path,
      storeAs: "selectedSearch",
    },
  ]);

  useFirestoreConnect([
    {
      collection: `users/${auth.uid}/projects/${pid}/searches`,
      storeAs: "selectedProject",
    },
  ]);


  const documents = useSelector((state) => database.data.selectedSearch);
  if (typeof pid === "undefined") {
    data = database.data.projects && database.data.projects[sid];
  } else {
    data = database.data.projects && database.data.projects[pid];

    searchtitle =
      database.data.selectedProject && database.data.selectedProject[sid];
  }

  const documentList = _.map(documents, (val, uid) => {
    return { ...val, uid };
  });

  const filteredDocs = documentList.filter((item) => {
    return item.item.title.toLowerCase().includes(search.toLowerCase());
  });

  const getRef = (docList, title) => {
    startDownload();
    generateRis(docList, title);
  };
  const onSearchChange = (event) => {
    setSearch(event.target.value);
  };

  function handleClick(nav) {
    navigate(nav);
  }

  return (
    <Page className={classes.root} title="Search">
      {!data ? (
        <ClipLoader size={105} color={theme.palette.primary.main} />
      ) : (
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link aria-current="page" color="inherit" underline="none">
              Scrutiny
            </Link>
            <Link
              aria-current="page"
              color="inherit"
              onClick={() => {
                if (typeof pid !== "undefined") {
                  handleClick(-2);
                } else {
                  handleClick(-1);
                }
              }}
            >
              Projects
            </Link>
            <Link
              aria-current="page"
              onClick={() => {
                if (typeof pid !== "undefined") {
                  handleClick(-1);
                }
              }}
              color="textPrimary"
            >
              {data.projectName}
            </Link>
            {searchtitle ? (
              <Link
                aria-current="page"
                // onClick={handleClick(`app/projects/p/${pid}/s/${sid}`)}
                color="textPrimary"
              >
                {searchtitle.projectName}
              </Link>
            ) : null}
          </Breadcrumbs>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => getRef(documentList, data.projectName)}
              color="primary"
              variant="contained"
            >
              Export as .RIS
            </Button>
          </Box>

          <Box mt={3}>
            <Card className={clsx(classes.card)}>
              <PerfectScrollbar>
                <Box ml={3} mt={3} mr={3} minWidth={1050}>
                  <TextField
                    fullWidth
                    className={classes.margin}
                    id="input-with-icon-textfield"
                    label="Search"
                    placeholder="Search saved documents"
                    onChange={onSearchChange}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box minWidth={1050}>
                  <List>
                    {!database.data.selectedSearch ||
                    database.status.requesting.selectedSearch === true ? (
                      <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        p={2}
                      >
                        <ClipLoader
                          size={105}
                          color={theme.palette.primary.main}
                        />
                      </Box>
                    ) : (
                      filteredDocs.map((doc) => (
                        <ListItem
                          onClick={() => handleClick(doc.uid)}
                          button={true}
                          key={doc.uid}
                        >
                          <ListItemIcon>
                            <FolderIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={doc.item.title}
                            secondary={doc.item.source}
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete">
                              <ChevronRightIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))
                    )}
                  </List>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      )}
    </Page>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.firebaseReducer.auth,
    database: state.firestoreReducer,
  };
}

export default compose(connect(mapStateToProps))(SearchPage);
