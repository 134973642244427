import {
    EMAIL_CHANGED,
    PASSWORD_CHANGED,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,
    LOGOUT_USER,
    SIGNED_IN,
    SIGNED_OUT
} from '../actions/types';

const INITIAL_STATE = { email: '', password: '', user: null, loading: false, error: '' }

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EMAIL_CHANGED:
            return { ...state, email: action.payload }
        case LOGIN_USER_SUCCESS:
            return { ...state, ...INITIAL_STATE, user: action.payload };
        case LOGIN_USER_FAIL:
            return {
                ...state, error: 'Auth failed', password: '', loading: false,
            };
        case LOGIN_USER:
            return { ...state, loading: true, error: '' };
        case PASSWORD_CHANGED:
            return { ...state, password: action.payload }
        case LOGOUT_USER:
            return { ...state, ...INITIAL_STATE };
        case SIGNED_IN:
            return { ...state, user: action.user };
        case SIGNED_OUT:
            return { ...state }
        default:
            return state;
    }
}