import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux'
import ClipLoader from "react-spinners/ClipLoader";
import theme from '../../theme'

import {
  Avatar,
  Box,
  Button,
  Menu,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { generateSingleRis, startDownload } from '../../actions/ProjectActions'
import MenuItem from '@material-ui/core/MenuItem';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

function openInNewTab(url) {
  var win = window.open(url, '_blank');
  win.focus();
}

function PaperCard ({ className, paper, risGen,...rest }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRef = (paper) => {
    startDownload()
    generateSingleRis(paper)
  }

  const getAuthors = () => {
        var authors = paper.authors;
        var printAuthors = ''
        var count = 1
        var max = authors.length
        console.log(max)
        authors.forEach(function (item) {
            if (count === max) {
                printAuthors += `${String(item.first_name)} ${String(item.last_name)} `
            } else {
                printAuthors += `${String(item.first_name)} ${String(item.last_name)}, `
            }
            count = count + 1
        })
        return printAuthors
    }


let authorNames = getAuthors()

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {paper.title}
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {paper.source}, {paper.year}
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {authorNames}
        </Typography>
        <Divider />
        <Box p={2}>

        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
          {paper.abstract}
        </Typography>
        </Box>
      </CardContent>
      <Box p={2}>
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          <Grid
            className={classes.statsItem}
            item
          >
            {!risGen ?
            <Button onClick={() => getRef(paper)} >
            <GetAppIcon
              className={classes.statsIcon}
              color="action"
            />
            <Typography
              color="textSecondary"
              display="inline"
              variant="body2"
            >
              Download reference file (.RIS)
            </Typography>
            </Button> :
               <ClipLoader
               size={105}
               color={theme.palette.primary.main}
               />
          }
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >

           <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} color="primary">View</Button>
           <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => {
              handleClose()
              openInNewTab(`https://dx.doi.org/${String(paper.identifiers.doi)}`)
              }}>View on Publisher website
            </MenuItem>
            {/* <MenuItem >View on scopus</MenuItem> */}
            <MenuItem onClick={() => {
              handleClose()
              openInNewTab(paper.link)
              }}>View on Mendelay</MenuItem>
          </Menu>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

PaperCard.propTypes = {
  className: PropTypes.string,
  paper: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    risGen: state.project.risGenerating
  }
}

export default connect(mapStateToProps, {generateSingleRis, startDownload})(PaperCard);
