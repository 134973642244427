import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import firebase from 'firebase/app';
import { createLogger } from 'redux-logger';
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore
import 'firebase/auth';
import 'firebase/firestore' // <- needed if using firestore
import thunk from 'redux-thunk';
import { createStore, compose, applyMiddleware } from 'redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { Provider } from 'react-redux';
import reducers from './reducers';
import routes from 'src/routes';

export const firebaseConfig = {
  apiKey: "AIzaSyB29-sf_Cq8KjnPId41_UbjjMeA7w9Zce4",
  authDomain: "scrutiny-f48fa.firebaseapp.com",
  databaseURL: "https://scrutiny-f48fa.firebaseio.com",
  projectId: "scrutiny-f48fa",
  storageBucket: "scrutiny-f48fa.appspot.com",
  messagingSenderId: "599233696049"
}

const logger = createLogger()
firebase.firestore() // <- needed if using firestore
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true
}


const store = createStore(
  reducers,
  {},
  compose(
      applyMiddleware(thunk, logger),
  )
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

const App = () => {
  const routing = useRoutes(routes);
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {routing}
        </ThemeProvider>
        </ReactReduxFirebaseProvider>
        </Provider>

  );
};

export default App;
