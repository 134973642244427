import React from 'react';
import { Link as RouterLink, useNavigate,} from 'react-router-dom';
import { connect } from 'react-redux'
import * as Yup from 'yup';
import { Formik } from 'formik';
import { signin } from '../../actions/AuthActions'
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import firebase from "firebase";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',

  },
  snackbarStyleViaContentProps: {
    backgroundColor: "transparent"
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: "lightgreen",
    color: "black"
  }
}));


function LoginView () {
  let navigate = useNavigate();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [open, setOpen] = React.useState(false);

  const openSnack = () => {
    setOpen(true);
  };

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function handleSubmit (values) {
    
    firebase
    .auth()
    .signInWithEmailAndPassword(values.email, values.password)
    .then(res => {
      navigate('/app/projects')})
    .catch(error => {
      formikRef.current.setSubmitting(false);
      console.log(error);
      openSnack();
    });
  }

  function googleSignIn () {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      navigate('/app/projects')
      // ...
    }).catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
  }

  function fbSignIn () {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      navigate('/app/projects')
      // ...
    }).catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
  }

  const classes = useStyles();
  const formikRef = React.createRef();

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        bgcolor='primary.main'
      >
        <Container 
        maxWidth="sm"
        bgcolor='primary.main'
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            bgcolor='white'
            padding={5}
            borderRadius={20}
          >
          <Formik
            innerRef={formikRef}
            initialValues={{
              email: 'example@gmail.com',
              password: 'Password123'
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => {   
            handleSubmit(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit} bgcolor='white'>
                <Box mb={1}         
>
                  <Typography
                    color="primary"
                    variant="h2"
                  >
                    sign in
                  </Typography>

                </Box>
               
                <Box
                  mt={1}
                  mb={1}
                  ml={10}
                  mr={10}
                  bgcolor='white'
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"

                  >
                  Login using your email address and password
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2} >
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Or...
                </Typography>
                <Box my={2} >
                    <Button
                      color='secondary'
                      fullWidth
                      startIcon={<GoogleIcon />}
                      onClick={googleSignIn}
                      size="large"
                      variant="contained"
                    >
                      Login with Google
                    </Button>
                  </Box>
                  <Box my={2} >
                    <Button
                      color='secondary'
                      fullWidth
                      startIcon={<FacebookIcon />}
                      onClick={fbSignIn}
                      size="large"
                      variant="contained"
                    >
                      Continue with Facebook
                    </Button>
                  </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Having trouble?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/auth/reset"
                    variant="h6"
                  >
                    Click here for help
                   </Link>
                </Typography>
              </form>
            )}
          </Formik>
          </Box>
        </Container>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={closeSnack}>
        <Alert onClose={closeSnack} severity="error">
        Sign in failed! Check your details and try again
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default connect(null, {signin})(LoginView);
