import {
    PROJECT_CREATE,
    PROJECT_CREATED,
    PROJECT_DELETED,
    SEARCH_FETCH_SUCCESS,
    SEARCH_FETCH_STARTED,
    DOCUMENT_FETCH_STARTED,
    DOCUMENT_FETCH_SUCCESS,
    PROJECT_SEARCH_TERMS,
    ADD_SEARCH_TO_SHARE,
    REMOVE_SEARCH_TO_SHARE,
    CLEAR_DOCUMENTS,
    DOCUMENT_SEARCH,
    ITEM_PROCESSED,
    SORT_DATE,
    ADD_TEMP_ITEM,
    CLEAR_PROJECTS,
    PROJECT_SEARCH,
    ONLY_READ,
    ONLY_UNREAD,
    VIEW_ALL,
    GEN_RIS_STARTED,
    RIS_GENERATED
} from '../actions/types';

const INITIAL_STATE = {
    projectName: '',
    initializedDate: '',
    type: '',
    view: 'all',
    searches: {},
    searchesFetching: false,
    documentsFetching: false,
    documents: {},
    searchTerms: '',
    selectedSearches: [],
    itemsProcessed: 0,
    sortBy: 'initializedDate',
    sortType: 'desc',
    tempItem: {},
    documentSearchTerm: '',
    projectSearchTerm: '',
    selectedSearch: {
        title: '',
        type: '',
        date: '',
        likes: '',
        uid: ''
    },
    selectedSearchTitle: '',
    selectedSearchDate: '',
    selectedSearchUID: '',
    selectedProjectTitle: '',
    selectedProjectDate: '',
    selectedProjectUID: '',
    selectedProject: {
        title: '',
        type: '',
        date: '',
        searches: '',
        likesno: '',
        uid: ''
    },
    risGenerating: false
};

export default (state = INITIAL_STATE, action) => {
    console.log(action)
    switch (action.type) {
        case PROJECT_CREATE:
            return { ...state, [action.payload.prop]: action.payload.value };
        case PROJECT_CREATED:
            return INITIAL_STATE;
        case PROJECT_SEARCH_TERMS:
            return { ...state, searchTerms: action.text };
        case PROJECT_DELETED:
            return { ...state };
        case SEARCH_FETCH_SUCCESS:
            return { ...state, searches: action.searches, searchesFetching: true, selectedProjectTitle: action.name, selectedProjectDate: action.date, selectedProjectUID: action.projectUID };
        case SEARCH_FETCH_STARTED:
            return { ...state, searches: {}, searchesFetching: false };
        case DOCUMENT_FETCH_STARTED:
            return { ...state, documents: {}, documentsFetching: true };
        case DOCUMENT_FETCH_SUCCESS:
            return {
                ...state, documents: action.documents, documentsFetching: false, searchesFetching: true, selectedSearchTitle: action.name, selectedSearchDate: action.date, selectedSearchUID: action.searchUID
            };
        case ADD_SEARCH_TO_SHARE:
            return { ...state, selectedSearches: [...state.selectedSearches, action.searchUID] };

        case REMOVE_SEARCH_TO_SHARE:
            return { ...state, selectedSearches: [...state.selectedSearches.filter(item => action.searchUID !== item)] };
        case CLEAR_DOCUMENTS:
            return { ...state, shareString: '', itemsProcessed: 0, documents: {}, selectedSearchTitle: '', selectedSearchDate: '', selectedSearchUID: '', documentSearchTerm: '' };
        case CLEAR_PROJECTS:
            return { ...state, shareString: '', itemsProcessed: 0, searches: {}, selectedProjectTitle: '', selectedProjectDate: '', selectedProjectUID: '', projectSearchTerm: '' };
        case ITEM_PROCESSED:
            return { ...state, itemsProcessed: state.itemsProcessed + 1 };
        case SORT_DATE:
            return { ...state, sortBy: action.sortBy, sortType: action.sortType };
        case DOCUMENT_SEARCH:
            return { ...state, documentSearchTerm: action.text }
        case PROJECT_SEARCH:
            return { ...state, projectSearchTerm: action.text }
        case ONLY_READ:
            return { ...state, view: 'read' }
        case ONLY_UNREAD:
            return { ...state, view: 'unread' }
        case VIEW_ALL:
            return { ...state, view: 'all' }
        case GEN_RIS_STARTED:
                return { ...state, risGenerating: true }
        case RIS_GENERATED:
            return {...state, risGenerating: false}
        default:
            return state;
    }
};