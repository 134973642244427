import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import Main from 'src/utils/main';
import AccountView from 'src/views/account/AccountView';
import ProjectListView from 'src/views/projects/ProjectListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import SearchPage from 'src/views/searchpage'
import ProjectPage from 'src/views/projectpage'
import PaperPage from 'src/views/paperpage'
import ResetView from 'src/views/auth/Reset'


const routes = [
  {
    path: '/',
    element: <Main />,
    children: [
      {  path: '*', element: <Navigate to="/error/404" /> }
    ]
  },
  {
    path: '/auth',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'reset', element: <ResetView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/auth/login" /> },
      { path: '*', element: <Navigate to="/error/404" /> }
    ]
  },
  {
    path: '/app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'projects', element: <ProjectListView /> },
      { path: 'projects/s/:sid', element: <SearchPage /> },
      { path: 'projects/s/:sid/:did', element: <PaperPage /> },
      { path: 'projects/p/:pid', element: <ProjectPage /> },
      { path: 'projects/p/:pid/s/:sid', element: <SearchPage /> },
      { path: 'projects/p/:pid/s/:sid/:did', element: <PaperPage /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/error',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFoundView /> },
    ]
  }
];


// const routes = [
//   {
//     path:'loading',
//     element: <Main/>
//   },
//   {
//     path: 'auth',
//     element: <MainLayout />,
//     children: [
//       { path: 'login', element: <LoginView /> },
//       { path: 'register', element: <RegisterView /> },
//       { path: '404', element: <NotFoundView /> },
//       { path: '/', element: <Navigate to="/auth/login" /> },
//       { path: '*', element: <Navigate to="/404" /> }
//     ]
//   },
//   {
//     path: 'app',
//     element: <DashboardLayout />,
//     children: [
//       { path: 'account', element: <AccountView /> },
//       { path: 'customers', element: <CustomerListView /> },
//       { path: 'dashboard', element: <DashboardView /> },
//       { path: 'products', element: <ProductListView /> },
//       { path: 'settings', element: <SettingsView /> },
//       { path: '*', element: <Navigate to="/404" /> }
//     ]
//   },
// ];



export default routes;
