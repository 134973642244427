import React, { useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Avatar,
  Card,
  Checkbox,
  Typography,
  Button
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import ClipLoader from "react-spinners/ClipLoader";
import theme from '../../../theme'
import _ from 'lodash'
import Page from 'src/components/Page';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useNavigate } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/Folder';
import SearchIcon from '@material-ui/icons/Search';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Toolbar from './Toolbar';
import data from './data';
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from "react-redux-firebase";
import { compose } from  'redux'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    card: {},
  }
}));



function ProjectListView({database, auth}) {
  let navigate = useNavigate();
  const classes = useStyles();

  function handleClick(nav) {
    navigate(nav)
  }

  useFirestoreConnect({
    collection: `users/${auth.uid}/projects`,
    storeAs: "projects",
  });

  const [search, setSearch] = useState('')
  const onSearchChange = (event) => {
    setSearch(event.target.value)
  }
  const projects = useSelector((state) => database.data.projects)

  const projectList = _.map(projects, (val, uid) => {
    return { ...val, uid }
  })

  const filteredProjects = _.orderBy(projectList.filter(item => {
    return item.projectName.toLowerCase().includes(search.toLowerCase())
  }), 'initializedDate', 'desc')

  console.log(database.data.projects)


  return (
    <Page
      className={classes.root}
      title="Projects"
    >
      <Container maxWidth={false}>
      <Breadcrumbs aria-label="breadcrumb">
      <Link         
        aria-current="page"
        color="inherit"  
        underline='none'
        >
        Scrutiny
      </Link>
      <Link         
        aria-current="page"
        color="textPrimary" onClick={() => handleClick("/app/projects")} >
        Projects
      </Link>

    </Breadcrumbs>
          <Box mt={3}>
            <Card
              className={clsx(classes.card,)}
            >
            <PerfectScrollbar>
              <Box ml={3} mt={3} mr={3} minWidth={1050}>
              <TextField
              fullWidth
                className={classes.margin}
                id="input-with-icon-textfield"
                label="Search"
                placeholder='Search your projects'
                onChange={onSearchChange}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                />

       
                </Box>
              <Box minWidth={1050}>
                <List>
                  {
                  !database.data.projects ?
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    p={2}
                  >        
                  <ClipLoader
                  size={105}
                  color={theme.palette.primary.main}
                  /> 
                  </Box>
                  :
                  filteredProjects.map((project) => (
                      <ListItem onClick={() => 
                      {
                        if (project.type === 'search') {
                          handleClick(`s/${project.uid}`);
                        } else {
                          handleClick(`p/${project.uid}`);
                        }
                      }} 
                      button={true} key={project.uid}>
                        <ListItemIcon>
                          {(project.type === 'project') ? <FolderIcon
                          color="primary"                   
                          /> :
                          <SearchIcon color="primary"/>
                          }
                          
                        </ListItemIcon>
                        <ListItemText
                          primary={project.projectName}
                          secondary={project.initializedDate.toDate().toDateString()}
                        />
                        <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <ChevronRightIcon                   
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                      </ListItem>
                  ))
                  }

                
                  </List>
              </Box>
            </PerfectScrollbar>

          </Card>        
        </Box>
      </Container>
    </Page>
  );
};


function mapStateToProps(state) {
  return {
    auth: state.firebaseReducer.auth,
    database: state.firestoreReducer
  }
}

export default compose(
  connect(mapStateToProps)
)(ProjectListView);
