import React from "react";
import { connect } from 'react-redux'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Navigate,
    useHistory,
    useLocation
  } from "react-router-dom";
  import LandingView from 'src/views/landing';



const Main = ({ auth }) => {
    return (
        <div style = {{height:"100vh"}}>
            {!auth.isLoaded ? <LandingView/> : !auth.isEmpty ? <Navigate to={{pathname: '/app/projects'}}/> : <Navigate to={{pathname: '/auth/login'}}/>}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.firebaseReducer.auth
    }
}

export default connect(mapStateToProps)(Main)