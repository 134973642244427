import React, { useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Avatar,
  Card,
  Checkbox,
  Typography,
  
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import PaperCard from './PaperCard'
import clsx from 'clsx';
import ClipLoader from "react-spinners/ClipLoader";
import theme from '../../theme'
import _ from 'lodash'
import Page from 'src/components/Page';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/Folder';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { compose } from  'redux'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    card: {},
  }
}));


function PaperPage({database, auth, prof}) {
  const classes = useStyles();
  let navigate = useNavigate();

  let { pid, sid, did } = useParams();

  useFirestoreConnect({
    collection: `users/${auth.uid}/projects`,
    storeAs: "projects",
  })
  useFirestoreConnect([
  {collection: `users/${auth.uid}/projects/${sid}/LikedDocs`, storeAs: 'selectedSearch'}
  ]);

  useFirestoreConnect([
  {collection: `users/${auth.uid}/projects/${pid}/searches`, storeAs: 'selectedProject'}
  ]);

  useFirestoreConnect([
    {collection: `users/${auth.uid}/projects/${pid}/searches/${sid}/LikedDocs`, storeAs: 'selectedProjectSearch'}
    ]);

  let path =''
  let data = ''
  let searchtitle = ''
  let paper = ''
  console.log(database.data.projects)
  console.log(database.data.selectedSearch)

  if (typeof pid === 'undefined') {
    console.log('searchpage')
    data = (database.data.projects && database.data.projects[sid])
    paper = database.data.selectedSearch && database.data.selectedSearch[did]
    console.log(data)
  } else {
    data = (database.data.projects && database.data.projects[pid])
    searchtitle = (database.data.selectedProject && database.data.selectedProject[sid])
    paper = database.data.selectedProjectSearch && database.data.selectedProjectSearch[did]
  }


  console.log(database.data.projects)


  function handleClick(nav) {
      navigate(nav); 
  }

  
  return (
    <Page
      className={classes.root}
      title="Scrutiny.app - Paper"
    >
      {(typeof paper === 'object' && paper !== null) ===false ?
                            <ClipLoader
                            size={105}
                            color={theme.palette.primary.main}
                            /> :

    
      <Container maxWidth={false}>
      <Breadcrumbs aria-label="breadcrumb">
      <Link         
        aria-current="page"
        color="inherit"  
        underline='none'
        >
        Scrutiny
      </Link>
      <Link         
        aria-current="page"
        color="inherit" 
        onClick={() => {
          if (typeof pid !== 'undefined') {
            console.log('projectclick')
            handleClick(-3)
          } else {
            console.log('Sea5rchclick')
            console.log(sid)
            handleClick(-2)
          }}}
         >
        Projects
      </Link>
        {!data ?
                <Link         
                aria-current="page"
                color="textPrimary"  
                onClick={() => {
                  if (typeof pid !== 'undefined') {
                    console.log('projectclick')
                    handleClick(-2)
                  } else {
                    console.log('Sea5rchclick')
                    console.log(sid)
                    handleClick(-1)
                  }}}
                >
                Paper
              </Link> :
                      <Link         
                      aria-current="page"
                      color="textPrimary"  
                      onClick={() => {
                        if (typeof pid !== 'undefined') {
                          console.log('projectclick')
                          handleClick(-2)
                        } else {
                          console.log('Sea5rchclick')
                          console.log(sid)
                          handleClick(-1)
                        }}}
                      >
                      {data.projectName}
                    </Link>
      }

      {
        searchtitle ?
      <Link          
        aria-current="page"
        color="textPrimary"  
        onClick={() => handleClick(-1)}
        >
        {searchtitle.projectName}
      </Link> : null
      }
      <Link         
        aria-current="page"
        color="textPrimary"  >
        {paper.item.title}
      </Link>
          </Breadcrumbs>
          <Box mt={3}>
            <Card
              className={clsx(classes.card,)}
            >
            <PerfectScrollbar>
              <PaperCard 
                paper={paper.item}
              />

            </PerfectScrollbar>

          </Card>        
        </Box>
      </Container>
}
    </Page>
  );
};


function mapStateToProps(state) {
  return {
    auth: state.firebaseReducer.auth,
    database: state.firestoreReducer,
    prof: state.firebaseReducer.isLoaded
  }
}

export default compose(
  connect(mapStateToProps),
)(PaperPage);
