import React from 'react';
import { checkAuthState } from '../../actions/AuthActions';
import { connect } from 'react-redux';
import { Link, Redirect,} from 'react-router-dom';
import firebase from 'firebase';
import { withStyles } from "@material-ui/core/styles";
import {
    Box,
    Container,
    Typography,
    makeStyles,
    colors
  } from '@material-ui/core';
import * as ROUTES from '../../routes';
import ClipLoader from "react-spinners/ClipLoader";
import Page from 'src/components/Page';

const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
  })(Typography);

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.primary.main,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    spinner: {
        marginTop: 50,
        display: 'block',
        maxWidth: '100%',
        width: 560,
      }
  }));

  const LandingView = () => {
    const classes = useStyles();
        return (
            <Page
            className={classes.root}
            title="Loading"
          >
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              <Container maxWidth="md">

              
                <Box textAlign="center">
                  
                    <ClipLoader
                    size={100}
                    color={"white"}
                    />    
                </Box>
              </Container>
            </Box>
          </Page>
        )

}

const mapStateToProps = ({ auth }) => {
    return {
        user: auth.user,
    };
}

// export default connect(mapStateToProps, { checkAuthState })((LandingView));

export default LandingView