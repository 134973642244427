import React, { useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Avatar,
  Card,
  Checkbox,
  Typography,
  InputAdornment,
  TextField
} from '@material-ui/core';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import theme from '../../theme'
import _ from 'lodash'
import Page from 'src/components/Page';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from "react-redux-firebase";
import { compose } from  'redux'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    card: {},
  }

}));


function ProjectPage ({className, database, auth}) {
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const onSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const classes = useStyles();
  let { pid } = useParams();
  let path = `users/${auth.uid}/projects/${pid}/searches`

  function handleClick(nav) {
    navigate(nav); 
  }

  useFirestoreConnect({
    collection: path,
    storeAs: "selectedProject",
  });

  const searches = useSelector((state) => database.data.selectedProject)

  const data = useSelector((state) => database.data.projects && database.data.projects[pid])

  const searchList = _.map(searches, (val, uid) => {
    return { ...val, uid }
  })

  const filteredDocs = searchList.filter((item) => {
    return item.projectName.toLowerCase().includes(search.toLowerCase());
  });


  return (
    <Page
      className={classes.root}
      title="Projects"
    >
      <Container maxWidth={false}>
      <Breadcrumbs aria-label="breadcrumb">
      <Link         
        aria-current="page"
        color="inherit"  
        underline='none'
        >
        Scrutiny
      </Link>
      <Link         
        aria-current="page"
        color="textPrimary" onClick={() => handleClick(-1)
        } >
        Projects
      </Link>
        {data ?
        
      <Link         
        aria-current="page"
        color="textPrimary" >
        {data.projectName}
      </Link> : null
      }

    </Breadcrumbs>
          <Box mt={3}>
            <Card
              className={clsx(classes.card,)}
            >
            <PerfectScrollbar>
            <Box ml={3} mt={3} mr={3} minWidth={1050}>
                  <TextField
                    fullWidth
                    className={classes.margin}
                    id="input-with-icon-textfield"
                    label="Search"
                    placeholder="Search folders"
                    onChange={onSearchChange}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              <Box minWidth={1050}>
                <List>
                  {
                  !database.data.selectedProject ?
                  <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          p={2}
        >        
                  <ClipLoader
                  size={105}
                  color={theme.palette.primary.main}
                  /> 
                  </Box>
                  :
                  filteredDocs.map((search) => (
                      <ListItem onClick={() => handleClick(`s/${search.uid}`)} button={true} key={search.uid}>
                        <ListItemIcon>
                          <SearchIcon                   
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={search.projectName}
                          // secondary={search.initializedDate.toDate().toDateString()}
                        />
                        <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <ChevronRightIcon                   
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                      </ListItem>
                  ))
                  }

                
                  </List>
              </Box>
            </PerfectScrollbar>

          </Card>        
        </Box>
      </Container>
    </Page>
  );
};


function mapStateToProps(state) {
  return {
    auth: state.firebaseReducer.auth,
    database: state.firestoreReducer
  }
}

export default compose(
  connect(mapStateToProps,)
)(ProjectPage);
